@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

:root {
  --primary-white: #ffffff;
  --primary-light-grey: #f4f4f4;
  --primary-grey: #ccd1d9;
  --primary-dark-grey: #666666;
  --primary-orange: #f9ab00;
  --primary-dark: rgb(52, 58, 64);
  --primary-red: #ef4035;
  --primary-hover-red: #f33501;

}

.contain{
  background-color: white;
  height: 100vh;
}

.homeClick{
  position: absolute;
}

body{
  margin:0px;
  padding: 0px;
  
  background-color: #161616;
  font-family: 'Montserrat', sans-serif;
}
*{
  box-sizing: border-box;
}
ul{
  list-style: none;
}
a{
  text-decoration: none;
}
button{
  outline: none;
  border: none;
}
input{
  outline: none;
  border: none;
}
.logo img{
  
  width: 50px;
  /* border-radius: 5px; */
}
#main{

  width:100%;
  height:850px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("images/background.png");
}
nav{
display: flex;
justify-content: space-around;
align-items: center;
box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.336);
position: fixed;
left: 0;
top: 0;
width:100%;
z-index: 1;
background-color: #0f0f0f;
}
nav ul{
display: flex;
}
.active{
  background-color: #6cdffa;
  color:#000000;
  box-shadow: 5px 10px 30px rgba(189, 198, 64, 0.411);
  transition: all ease 0.2s;
}

nav ul li a{
  font-family: calibri;
height:40px;
line-height: 43px;
margin: 3px;
padding: 0px 22px;
display: flex;
font-size: 0.8rem;
text-transform: uppercase;
font-weight: 500;
color:#ffffff;
  letter-spacing: 1px;
  border-radius: 3px;
  transition: 0.2s ease-in-out;
}

nav ul li a:focus{
  background-color: #6cdffa;
  color:#000000;
  box-shadow: 5px 10px 30px rgba(189, 198, 64, 0.411);
  transition: all ease 0.2s;
}

.hey{
  font-family: calibri;
color:#ffffff;
font-weight: 500;
font-size: 0.9rem;
border-bottom: 2px solid #000266;
}
nav ul li a:hover{
background-color: #6cdffa;
  color:#000000;
box-shadow: 5px 10px 30px rgba(189, 198, 64, 0.411);
transition: all ease 0.2s;
cursor: pointer;
}
nav .menu-btn,
.menu-icon{
  display:none;
}
.name{
font-family: 'Montserrat';
width: 500px;
position: absolute;
  left: 50%;
  text-align: center;
top: 50%;
transform: translate(-50%,-50%);
}
.name span{
  color: #6cdffa;
  border-bottom: 2px solid #6cdffa;
}
.name .details{
  color: #969696;
}
.name h1{
font-family:'Montserrat';
font-size: 46px;
margin:0px;
letter-spacing: 3px;
color:#ffffff;
}
.header-btns{
  display: flex;
  margin-top: 40px;
  margin-left: 6.8em;
}

.SnakesLTD-btns{
  margin-top: -30px;
}

.SnakesLTD-btns  > :first-child img{

  /* width: auto; */

  /* height: auto; */

  width: 18%;

  margin-bottom: .1%;

}

.SnakesLTD-btns  > :nth-child(n+2) img{

  /* width: auto; */

  /* height: auto; */

  width: 19.5%;

}

.cv-btn{
width:110px;
height: 40px;
display: flex;
justify-content: center;
  align-items: center;
  background-color: #6cdffa;
  box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.137);
  border-radius: 10px;
color:#000266;
cursor: pointer;
}
.cv-btn1{
  font-size: 14px;
width:140px;
height: 40px;
display: flex;
justify-content: center;
  align-items: center;
  background: transparent;
  border: 1px solid #ffffff;
  color:#ffffff;
  border-radius: 10px;
  margin-left: 20px;
}
.cv-btn:hover{
background-color: #ffffff;
  transition: all ease 0.5s;
  color: #000266;
}
.cv-btn1:hover{
background-color: #ffffff;
  transition: all ease 0.5s;
  color: #000266;
}

.arrow{
  align-self: end;
  width: 50%;
  height: 15%;
  margin-bottom: 4em;
  position: absolute;
  bottom:0px;
  border-right: 2px solid  #6cdffa;
  animation: arrow-animation ease 1.5s;

}
.arrow::after{
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 11px 11px 0px 11px;
  border-color: #6cdffa transparent transparent transparent;
  right: -0.7em;
  bottom: -2px;
}
@keyframes arrow-animation{
  0%{
      bottom: 70px;
      opacity: 0.2;
  }
  100%{
      bottom: 5px;
      opacity: 1;
  }
}
.f-heading h1{
  color:#FFFFFF;
  font-size: 2.5rem;
  margin: 0px;
  padding: 0px;

}
.f-heading p{
  color:rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin-top: 10px;
  padding: 0px;
}
.f-heading{
  text-align: center;
  margin-top: 60px;
  margin-bottom: -120px;
}
#features{
    /* margin-top:140px ; */
width:100%;
/* height: 120vh; */
background-color:#000000;
box-sizing: border-box;
display: flex;
align-items: center;
justify-content: space-between;
padding: 50px 5% 0px 5%;
position: relative;
}

.features-text{
  /* width: 45%; */
  color: #fff;
  list-style: circle;
}
.features-text h2{
font-size: 2rem;
color:#6cdffa;
font-weight: 400;
}
.features-text h2{
margin: 0px;
padding: 0px;
}
.features-text p{
/* font-size: 1.2rem; */
color:#b4b4b4;

}
.features-text button{
  margin-top: 20px;
/* width: 140px; */
/* height: 40px; */
border-radius: 10px;
border: none;
  outline: none;
  color: #000266;
background-color:#6cdffa;
cursor: pointer;
}
.features-text button:hover{
background-color: #ffffff;
  transition: all ease 0.3s;
  color: #000266;
}
.features-model{
  /* width: 50%; */
}
.features-model img{
/* width: 500px; */
}

#services{
  /* margin-top: 120px; */
  /* width:100%; */
  /* height:125vh; */
  box-sizing: border-box;
  font-family: calibri;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px ;
}
.text-overlay {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 0; /* Adjust the top position as needed */
  left: 0; /* Adjust the left position as needed */
  padding: 45px; /* Add padding to the text */
}

/* Style for the text within the overlay */
.text-overlay p {
  margin: 0; /* Remove default margin */
  font-size: 30px; /* Adjust the font size as needed */
  color: white; /* Text color */
}

/* #boxThree{
  width: 47.35%;
} */

.s-heading h1{
  color:#FFFFFF;
  font-size: 3rem;
  margin: 0px;
  padding: 0px;

}
.s-heading p{
  color:rgba(247, 247, 247, 0.678);
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
}
.s-heading{
  text-align: center;
  margin: 20px 0px;
}
.s-box{
  background-color: #ffffff;
  width: 350px;
  height: 690px;
  margin: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 12px rgba(0,0,0,0.1);
  position: relative;
  width: auto;
}

.b-container {
  display: flex;
  /* display: contents; */
  flex-wrap: wrap; 
  justify-content: center; 
  position: relative;
}

.b-container > :first-child {
  width: 65%; 
  height: 70%;
}

.b-container img:first-child {
  border: 2px solid white;
}




/* .b-container {
  width: 45%; 
  height: 45%;
} */

/* .b-container{
  display: flex;
  justify-content: center;
  align-content: center;
} */

/* .b-container > :first-child {
  width: 100%; 
  margin-bottom: 10px; 
} */


/* .b-container > :nth-child(n+2) {
  width: auto;
} */

.no-edit-cursor {
  cursor: pointer; /* Use 'pointer' cursor style on hover */
}

.s-b-img{
  width: 100%;
  height: 73%;

}
.s-b-img img{
  width:100%;
  height: 100%;

}
.s-b-text{
  width: 100%;
  height: 26.5%;
  background-color:#ffffff;
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
}
.s-b-text p{
  margin: 0px;
  color:#000266a6;
  font-size: 1.1rem;
  font-family: calibri;
  display: block;
  display: -webkit-box;
  max-width: 80%;
  margin-top: 10px;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.s-b-text p:hover{
  color:#000266;
}
.s-b-text a{
  margin-top:15px ;
}
.s-type{
  font-family: calibri;
  color:#000266;
  background-color: #ffffff;
  padding: 10px 15px;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 2px 2px 12px rgba(0,0,0,0.2);
}
.s-box:hover{
  transform:translateY(-10px);
  transition: all ease 0.3s;

}

#contactus{
  background-color: #6cdffa;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  margin-top: 80px;
  padding: 30px 0px;
}
#contactus h3{
font-size:2.5rem;
margin:20px;
color: #000000;
}
.contactus-input{
width:400px;
height:50px;
background-color:#FFFFFF;
display:flex;
justify-content: center;
border-radius: 40px;
text-align: center;
box-shadow: 2px 2px 30px rgba(0,0,0,0.15);
}
.contactus-input input{
width:100%;
background-color: transparent;
border:none;
outline: none;
text-align: center;
color:#242425;
}

.contactus-input2{
  width:400px;
  text-align: center;
  height:120px;
  background-color:#FFFFFF;
  display:flex;
  justify-content: center;
  border-radius: 17px;
  box-shadow: 2px 2px 30px rgba(0,0,0,0.15);
  }

.contactus-input2 textarea{
  width:100%;
  background-color: transparent;
  border:none;
  outline: none;
  text-align: center;
  color:#242425;
  }

.mybtn{
  text-align: -webkit-center;
}

.mybtn button{
  width:200px;
  height:35px;
  background-color:#6cdffa;
  color:#000000;
  display: flex;
  font-size: 0.9rem;
  justify-content: center;
  align-items: center;
  margin: auto 10px;
  border-radius: 20px;
  font-weight: 500;
  transition: 0.4s ease-in;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  margin: 1.6rem;
  border: 1px solid var(--primary-red);
  cursor: pointer;
}
.mybtn button:hover{
  background-color:#000000;
  color:#6cdffa;
}

/* .btn-main-offer {
  border: 1px solid var(--primary-red);
  text-transform: uppercase;
  border-radius: 0;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: var(--primary-hover-red);
  color: var(--primary-white);
  transition:  .2s ease-in-out;
} */

.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  /* background: none; */
  border-radius: 10px;
  width: 50%;
}

.error-message {
  color: var(--primary-white);
  background: var(--primary-hover-red);
  text-align: left;
  font-size: .85rem;
}

.success-message {
  font-weight: 900;
  color: var(--primary-green);
}

@media(max-width:1190px){
#main{
  background-size: 1150px !important;
  
}
.name{
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.header-btns{
  margin-left: 10em;
}

.features-model img{
  height:400px;
  }
  #services{
  height:auto;
  }
  .b-container{
  flex-wrap: wrap;
}
  .s-box{
  flex-grow: 1;
}
.s-b-img img{
  object-fit:cover;
}

}

@media(max-width:970px){
 
  #main{
    background-size: 970px !important;
  }
  #services{
    margin-top: -60px;
  }
.features-model{
  display: none;
}
.s-heading h1{
  font-size: 1rem;
  color: #6cdffa;
}
.features-text h2{
  font-weight: 600;
}
.features-text h3{
  font-size: .9rem;
}
.features-text p{
  font-size: .75rem;
}
.b-container{
  margin-top: 30px;
}
#contactus h3{
  font-size: 2.5rem;
}
#features{
  /* margin-top: -60px; */
  justify-content: center;
  padding-top: 0px;
  font-size: 2rem;
  /* text-align: center; */
  text-align: -webkit-left;
}
.f-heading{
  display: none;
}
.features-text{
  width: 90%;
}
.features-text h1{
  font-size:4rem;
  }

}


@media(max-width:600px){
  #main{
    background-size: 600px !important;
    
  }
.name{
  width:60%;
  }}
  .scroll{
      left: 42.5%;
  }



@media(max-width:1100px){.menu-icon{
  display:block;}
      nav{
          justify-content: space-between;
          height: 65px;
          padding: 0px 30px;
      }
      
      .SnakesLTD-btns  > :first-child img{

        width: 25%;
      
        margin-bottom: .1%;
      
      }
      
      .SnakesLTD-btns  > :nth-child(n+2) img{
      
        width: 25.5%;
      
      }

      .logo img{
          width:70px;
          border-radius: 5px;
      }
      .header-btns{
          margin: 0;
      }
      .menu{
          display:none;
          position:absolute;
          top:65px;
          left:0px;
          background-color:#000000;
          border-bottom:4px solid #6cdffa;
          width:100%;
          padding:0px;
          margin:0px;
      }
      .menu li{
          width:100%;
      }
      nav .menu li a{
          width:100%;
          height:40px;
          justify-content: center;
          align-items: center;
          margin:0px;
          padding: 25px;
          border:1px solid rgba(158, 155, 0, 0.03);
      }
      nav .menu-icon{
          cursor:pointer;
          float:right;
          padding:28px 20px;
          position:relative;
          user-select: none;
      }
      nav .menu-icon .nav-icon{
          background-color:#6cdffa;
          position: absolute;
          display:block;
          height:2px;
          position:relative;
          transition: background 0.2s ease-out;
          width:18px;
      }
      nav .menu-icon .nav-icon:before,
      nav .menu-icon .nav-icon:after{
          background:#6cdffa;
          content:'';
          display:block;
          height:100%;
          position:absolute;
          transition:all ease-out 0.2s;
          width:100%;
      }
      nav .menu-icon .nav-icon:before{
          top:5px;
      }
      nav .menu-icon .nav-icon:after{
          top:-5px;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon{
          background:transparent;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon:before{
          transform: rotate(-45deg);
          top:0;
      }
      nav .menu-btn:checked ~ .menu-icon .nav-icon:after{
          transform: rotate(45deg);
          top:0;
      }
      nav .menu-btn{
          display:none;
      }
      nav .menu-btn:checked ~ .menu{
          display:block;
      }}
      @media(max-width:400px){
          .s-box{
              width: 90%;
              height:400px;
          }
      }
      @media(max-width:376px){
        .contactus-input{
          width:300px;
          height:50px;
          }
          nav .menu-icon{
            position: absolute;
            margin-left: 34.5%;
        }
        }

      